import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  flex: 1;
  width: 100%;
  border-top: 1px solid #e2e2e2;
`;

export const FooterDesktopContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  flex: 1 1 auto;
  margin: 0 auto;

  @media screen and (max-width: 766px) {
    display: none;
  }
`;

export const FooterDesktopMain = styled.section`
  display: flex;
  margin: 0 auto;
  padding: ${({ noAddress }) => (noAddress ? '3rem 2rem 1.5rem' : '3rem 1rem 1.5rem')};
  width: 100%;
  justify-content: space-between;
  max-width: ${({ noAddress }) => (noAddress ? '780px' : '1200px')};

  & > div {
    display: block;
    ${({ noAddress }) =>
      noAddress
        ? `
      flex: 0 0 auto
    `
        : `width: 100%;`}
  }

  & > div::nth-of-type(1),
  & > div::nth-of-type(3) {
    flex-grow: 2;
    flex-shrink: 2;
  }

  & > div::nth-of-type(2) {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

export const StoreDescription = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StoreDetails = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 767px) {
    margin: ${({ noAddress }) => (noAddress ? '0 20px' : '0')};
  }
`;

export const StoreMapComp = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const StoreNameImage = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media screen and (min-width: 767px) {
    align-items: center;
  }

  @media screen and (max-width: 766px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const StoreImage = styled.div`
  object-position: center center;
  object-fit: cover;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 767px) {
    margin-right: 15px;
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
  }

  @media screen and (max-width: 766px) {
    flex: 0 0 55px;
    width: 55px;
    height: 55px;
  }
`;

export const StoreName = styled.p`
  margin: 0;
  flex: 1;
  color: #000000;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 767px) {
    font-size: 24px;
    -webkit-line-clamp: 3;
  }

  @media screen and (max-width: 766px) {
    font-size: 21px;
    -webkit-line-clamp: 4;
  }
`;

export const StoreAboutUs = styled.div`
  @media screen and (max-width: 766px) {
    margin-bottom: 20px;
  }
`;

export const FooterLabel = styled.p`
  letter-spacing: 0.37px;
  color: #000000;
  margin: 0;

  @media screen and (min-width: 767px) {
    font-size: 16px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 766px) {
    font-size: 11px;
    margin-bottom: 5px;
  }
`;

export const FooterPara = styled.p`
  color: #747373;
  letter-spacing: 1.4px;
  max-width: 300px;
  margin: 0;
  word-break: break-word;

  ${(props) =>
    props.textSelection &&
    `
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;  
  `}

  ${(props) =>
    props.needLineClip &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  @media screen and (min-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 766px) {
    font-size: 12px;
  }
`;

export const ViewMoreText = styled.span`
  font-size: 11px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  color: #2a2a2a;
  line-height: 1.4em;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterTrademarkComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 767px) {
    border-top: 1px solid #bebebe;
    padding: 30px 0;
  }

  @media screen and (max-width: 766px) {
    flex-direction: column;
    padding: 10px 0;
  }
`;

export const FooterTrademark = styled.p`
  margin: 0;
  color: #bebebe;
  padding: 10px;

  & > a,
  & > a:visited,
  & > a:active,
  & > a:visited {
    color: inherit;
  }

  & > a:not(:hover) {
    text-decoration: none;
  }

  @media screen and (min-width: 767px) {
    font-size: 12px;
    &:first-child {
      border-right: 1.5px solid #bebebe;
    }
  }

  @media screen and (max-width: 766px) {
    font-size: 11px;
  }
`;

export const Circle = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  position: relative;
  background-color: #747373;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
`;

export const Section = styled.section`
  display: ${(props) => props.display || 'block'};
  margin: 0 0 20px;
  ${(props) =>
    props.display &&
    `
    margin: 0; 
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #707070;
  `}

  & > a,
  & > a:visited,
  & > a:active,
  & > a:visited {
    color: inherit;
    text-decoration: none;
  }
`;

export const FooterButton = styled.span`
  display: flex;
  flex: 0 0 auto;
  width: fit-content;
  position: relative;
  padding: 6px 8px;
  border-radius: ${(props) => props.theme.roundness || '14px'};
  border-style: solid;
  border-width: 0.4px;
  border-color: ${(props) => props.color};
  cursor: pointer;

  & > a {
    display: flex;
    text-decoration: none;
  }
`;

export const PhoneNumber = styled.span`
  color: ${(props) => props.color};
  display: inline-block;
  margin-left: 10px;

  @media screen and (min-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 766px) {
    font-size: 12px;
  }
`;

export const PaymentRow = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentOption = styled.span`
  display: inline-block;

  @media screen and (min-width: 767px) {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 766px) {
    margin-left: 10px;
  }
`;

export const PaymentCash = styled.span`
  font-size: 14px;
  text-transform: capitalize;

  @media screen and (max-width: 766px) {
    font-size: 9px;
  }
`;

export const FooterMobileContainer = styled.section`
  width: 100%;

  @media screen and (min-width: 767px) {
    display: none;
  }
`;

export const FooterMobileMain = styled.div`
  display: flex;
  padding: 30px 20px 0px;
  flex-direction: column;
`;

import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import {
  FooterContainer,
  FooterDesktopContainer,
  FooterDesktopMain,
  FooterTrademarkComponent,
  StoreAboutUs,
  FooterLabel,
  FooterPara,
  StoreDescription,
  StoreDetails,
  StoreImage,
  // StoreMapComp,
  StoreName,
  StoreNameImage,
  Section,
  FooterButton,
  PhoneNumber,
  PaymentRow,
  PaymentOption,
  PaymentCash,
  FooterMobileContainer,
  FooterMobileMain,
  Circle,
  FooterTrademark,
  ViewMoreText,
} from './Footer.styles';
import { PhoneIcon } from '@/assets/svgExports/PhoneIcon';
import { InfoIcon } from '@/assets/svgExports/InfoIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceInfo } from '@/utils/deviceInfo';
import { useRouter } from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import SubscriberInput from '../../SubscriberInput';
import LogoutModal from '@/components/LogoutModal';
import { deviceWidth } from '@/utils/deviceWidth';
import { commonToggleAuthModal } from '@/utils/commonToggleAuthModal';
import { removeDsAdsRouteLink } from '@/utils/removeDsAdsRouteLink';
import { executeLogout } from '@/utils/excuteLogout';
import { AdContainer } from '../../MiddleBody/MiddleBody6/Hybrid.styles';
import HorizontalReasonModal from '../../DigitalShowroomAds/HorizontalReasonModal';
import ReasonModalHOC from '../../DigitalShowroomAds/ReasonModalHOC';
import getCustomCtaText from '@/utils/getCustomCtaText';
import { FooterMap } from '@/components/FooterMap';
import {
  CANCELATION_AND_REFUND_POLICY_URL,
  DS_TERMS_AND_CONDITIONS_URL,
  MAX_MOBILE_WIDTH,
} from '../../../../utils/constants';
import { FooterStorePolicies } from '../FooterStorePolicies';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { getFooterDSLabel } from '../footerUtils';
import { useSSRSelector } from '@/redux/ssrStore';
import useStoreLogoOrNameVisibility from '@/hooks/useStoreLogoOrNameVisibility';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import getDummyOrActualContactDetails from '@/utils/getContactDetails';
import checkIfThemePreview from '@/utils/checkIfThemePreview';
import { getStorePolicies } from '@/redux/actions';

const PremiumFooter = (props) => {
  const router = useRouter();
  const { user } = useSelector((state) => ({
    user: state.userReducer,
  }));
  const { store, storePolicies } = useSSRSelector((state) => ({
    store: state.storeReducer.store,
    storePolicies: state.storePolicyReducer.data,
  }));
  const { leadGenFeatureStatus, visitorLoginFeatureStatus } = useFeatureLocks();
  const isLeadGenVisible = leadGenFeatureStatus?.isVisible;
  const isVisitorLoginVisible = visitorLoginFeatureStatus?.isVisible;

  const [sColor, setColor] = useState('');
  const [isDesktop, setIsDesktop] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({
    OSName: null,
    ownerNumber: null,
  });
  const [fullYear, setFullYear] = useState(null);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showAdInfo, setShowAdInfo] = useState(false);
  const isPreview = checkIfThemePreview();
  const dispatch = useDispatch();

  useEffect(() => {
    const thisYear = new Date();
    setFullYear(thisYear.getFullYear());
    const { OSName } = getDeviceInfo();
    setState((state) => ({
      ...state,
      OSName: OSName,
      ownerNumber: store && store?.owner?.phone,
    }));
  }, [store]);

  useEffect(() => {
    if (store?.theme?.colors?.primary_color) {
      setColor(store?.premium ? store?.theme?.colors?.primary_color : '#000000');
    }
  }, [store?.theme?.colors?.primary_color]);

  useEffect(() => {
    if (deviceWidth > 766) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
    setIsLoaded(true);
  }, [deviceWidth > 0]);

  useEffect(() => {
    setShowLogoutConfirmation(false);
  }, [user?.isLogin]);

  useEffect(() => {
    if (store?.store_id && !storePolicies?.length) {
      dispatch(getStorePolicies({ store_id: store.store_id }));
    }
  }, [store?.store_id, storePolicies?.length]);

  const toggleAuthModal = () => {
    if (
      commonToggleAuthModal({
        userLogin: user?.isLogin,
        storeInfoDomain: store?.store_info?.domain,
      })
    )
      toggleLogoutModal();
  };

  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  function renderSocialMediaProfileDetails(isMobileView) {
    if (
      !store.social_media_profile_details?.media_count ||
      store.social_media_profile_details?.media_count < 1
    )
      return null;
    return (
      <div
        className="footer-social-media-container"
        style={isMobileView ? {} : { width: '246px' }}
      >
        <p>Follow us here</p>
        <div className="social-media-section flex space-between" style={{ gap: '16px' }}>
          {store.social_media_profile_details?.media_detail.map((profile) => {
            return profile.profile_url !== '' ? (
              <div
                key={profile.id}
                className="social-media-icon"
                style={{ height: '24px', width: '24px' }}
              >
                <a href={profile.profile_url} target="_blank" rel="noreferrer">
                  <img
                    src={profile.image_url}
                    alt={`${profile?.name} image`}
                    loading="lazy"
                  />
                </a>
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  }

  function renderLoginLogoutDesktop() {
    return (
      <>
        <div className="footer-login-logout" onClick={toggleAuthModal}>
          <div className="footer-login-logout-button fw5 pointer">
            <span style={{ fontSize: 12 }}>{user?.isLogin ? 'Logout' : 'Login'}</span>
          </div>
        </div>
      </>
    );
  }

  function renderLoginLogoutMobile() {
    return (
      <>
        <div className="footer-login-logout" onClick={toggleAuthModal}>
          <div className="footer-login-logout-button fw5 pointer">
            <span style={{ fontSize: 12 }}>{user?.isLogin ? 'Logout' : 'Login'}</span>
          </div>
        </div>
      </>
    );
  }

  function authAndLogoutModal() {
    return (
      <>
        {showLogoutConfirmation && (
          <LogoutModal
            toggleLogoutModal={toggleLogoutModal}
            executeLogout={executeLogout}
            showLogoutConfirmation={showLogoutConfirmation}
          />
        )}
      </>
    );
  }

  const callNumber = (e) => {
    if (
      !((state.OSName === 'Android' || state.OSName === 'iPhone') && deviceWidth < 766)
    ) {
      e.preventDefault();
    }
  };

  function toggleAdReasonModal() {
    setShowAdInfo(!showAdInfo);
  }

  function renderDesktopAds() {
    return (
      !isPreview && (
        <>
          {deviceWidth > MAX_MOBILE_WIDTH &&
          store.store_info?.non_premium_ad_url_desktop_horizontal &&
          !store?.theme?.is_demo ? (
            <AdContainer paddingTop={0} paddingBottom={42}>
              <div className="flex flex-column digital-showroom__ads-footer items-center">
                <div
                  className="flex footer-ads___width"
                  onClick={() => setShowAdInfo(true)}
                >
                  <div className="flex f8px o-50 mb6px black-90 f14px pointer">
                    Why I am seeing this
                  </div>
                  <div className="ml10px f10px pointer">
                    <InfoIcon size={16} secondaryColor={'#7F7F7F'} />
                    {showAdInfo && (
                      <ReasonModalHOC
                        toggleAdReasonModal={toggleAdReasonModal}
                        reasonModal={HorizontalReasonModal}
                        ml={-0.1}
                        mt={-0.3}
                        translateX={0}
                        translateY={0}
                        class={'horizontal-reason-modal mt8px'}
                      />
                    )}
                  </div>
                </div>
                <div className="flex pointer items-start" onClick={removeDsAdsRouteLink}>
                  <Image
                    src={store.store_info?.non_premium_ad_url_desktop_horizontal}
                    width={877}
                    height={187}
                  ></Image>
                </div>
              </div>
            </AdContainer>
          ) : null}
        </>
      )
    );
  }

  return isLoaded ? (
    <FooterContainer>
      {isDesktop ? (
        <FooterDesktop
          callNumber={callNumber}
          authAndLogoutModal={authAndLogoutModal}
          renderLoginLogoutDesktop={renderLoginLogoutDesktop}
          renderDesktopAds={renderDesktopAds}
          router={router}
          noDescription={props.noDescription}
          store={store}
          sColor={sColor}
          fullYear={fullYear}
          renderSocialMediaProfileDetails={renderSocialMediaProfileDetails}
          storePolicies={storePolicies}
          showSubscriberInput={isLeadGenVisible}
          showVisitorLogin={isVisitorLoginVisible}
          isPaymentNotVisible={
            props?.config?.hidden_components?.payment_method_images_not_visible
          }
        />
      ) : (
        <FooterMobile
          callNumber={callNumber}
          authAndLogoutModal={authAndLogoutModal}
          renderLoginLogoutMobile={renderLoginLogoutMobile}
          router={router}
          noDescription={props.noDescription}
          store={store}
          sColor={sColor}
          fullYear={fullYear}
          storePolicies={storePolicies}
          renderSocialMediaProfileDetails={renderSocialMediaProfileDetails}
          showSubscriberInput={isLeadGenVisible}
          showVisitorLogin={isVisitorLoginVisible}
          isPaymentNotVisible={
            props?.config?.hidden_components?.payment_method_images_not_visible
          }
        />
      )}
    </FooterContainer>
  ) : (
    <div></div>
  );
};

const FooterDesktop = ({
  store,
  sColor,
  noDescription,
  router,
  callNumber,
  fullYear,
  authAndLogoutModal,
  renderLoginLogoutDesktop,
  showVisitorLogin,
  renderDesktopAds,
  renderSocialMediaProfileDetails,
  storePolicies,
  showSubscriberInput,
  isPaymentNotVisible,
}) => {
  const dsLabel = getFooterDSLabel(store?.theme?.components);
  const { isStoreLogoInFooter, isStoreNameInFooter } = useStoreLogoOrNameVisibility();
  const storeLogo = getActualOrDsStoreLogo(store);
  const { storeAddress, storePhone } = getDummyOrActualContactDetails(store, true);

  return (
    <>
      {authAndLogoutModal()}
      <FooterDesktopContainer>
        <FooterDesktopMain noAddress={store?.address?.visibility === 2}>
          <StoreDescription>
            <StoreNameImage>
              {storeLogo && isStoreLogoInFooter && (
                <StoreImage>
                  <img alt="store logo" src={storeLogo} />
                </StoreImage>
              )}
              {store?.store_info?.name && isStoreNameInFooter && (
                <StoreName>{store?.store_info?.name}</StoreName>
              )}
            </StoreNameImage>
            {!noDescription && store?.store_info?.description && (
              <StoreAboutUs>
                <FooterLabel>About Us</FooterLabel>
                {!!store?.store_info?.description &&
                store?.store_info?.description.length > 160 ? (
                  <FooterPara textSelection={true}>
                    {store?.store_info?.description.substr(0, 155)}...
                  </FooterPara>
                ) : (
                  <FooterPara textSelection={true} needLineClip={true}>
                    {store?.store_info?.description}
                  </FooterPara>
                )}
                {store?.store_info?.description.length > 160 && (
                  <ViewMoreText
                    onClick={() => {
                      router.push(getRoute(PATH.ABOUT_US, store?.store_info?.domain));
                    }}
                  >
                    View More
                  </ViewMoreText>
                )}
              </StoreAboutUs>
            )}
            {showVisitorLogin && renderLoginLogoutDesktop()}
          </StoreDescription>
          <StoreDetails noAddress={store?.address?.visibility === 2}>
            <Section>
              {storePhone && (
                <>
                  <FooterLabel>Contact us</FooterLabel>
                  <FooterButton
                    color={sColor || store?.store_info?.theme?.colors?.primary_color}
                  >
                    <a href={`tel:${storePhone}`} onClick={callNumber}>
                      <span className="footer-button-phone-icon">
                        <PhoneIcon
                          color={
                            sColor || store?.store_info?.theme?.colors?.primary_color
                          }
                          size={12}
                        />
                      </span>
                      <PhoneNumber
                        color={sColor || store?.store_info?.theme?.colors?.primary_color}
                      >
                        {storePhone}
                      </PhoneNumber>
                    </a>
                  </FooterButton>
                </>
              )}
            </Section>
            {showSubscriberInput ? (
              <Section>
                <SubscriberInput />
              </Section>
            ) : null}
            <Section>
              {storeAddress && (
                <>
                  <FooterLabel>Visit Us</FooterLabel>
                  <FooterPara>{storeAddress}</FooterPara>
                </>
              )}
            </Section>
            <Section>
              {store?.social_media_profile_details &&
                renderSocialMediaProfileDetails(false)}
            </Section>
            {!isPaymentNotVisible && !store?.services?.delivery_time_approx && (
              <Section style={{ display: 'none' }}>
                <FooterLabel>{getCustomCtaText('pay_after_cta')}</FooterLabel>
                <PaymentRow>
                  <PaymentOption>
                    <Image
                      src="/assets/images/ic_paytm@2x.png"
                      alt="Paytm"
                      height={31}
                      width={63}
                      layout="fixed"
                    />
                  </PaymentOption>
                  <PaymentOption>
                    <Image
                      src="/assets/images/ic_gpay@2x.png"
                      alt="GPay"
                      height={27}
                      width={57}
                      layout="fixed"
                    />
                  </PaymentOption>
                  <PaymentOption>
                    <Image
                      src="/assets/images/ic_phonep@2x.png"
                      alt="Phonpe"
                      height={25}
                      width={25}
                      layout="fixed"
                    />
                  </PaymentOption>
                  <PaymentOption>
                    <PaymentCash>Or Cash</PaymentCash>
                  </PaymentOption>
                </PaymentRow>
              </Section>
            )}
          </StoreDetails>
          <FooterMap storeData={store} />
        </FooterDesktopMain>
        <FooterStorePolicies storePolicies={storePolicies} path={PATH.STORE_POLICIES} />
        {renderDesktopAds()}
        <FooterTrademarkComponent>
          <FooterTrademark>
            Copyright {fullYear}
            {dsLabel ? <>. {dsLabel}</> : ''}
            {' | '}
          </FooterTrademark>
          <FooterTrademark>
            <a target="_blank" href={CANCELATION_AND_REFUND_POLICY_URL} rel="noreferrer">
              Refunds &amp; Cancellation
            </a>
            <Circle />
            {store?.services?.delivery_time_approx ? (
              <a target="_blank" href={DS_TERMS_AND_CONDITIONS_URL} rel="noreferrer">
                Terms &amp; Conditions
              </a>
            ) : (
              <a target="_blank" href={DS_TERMS_AND_CONDITIONS_URL} rel="noreferrer">
                Terms &amp; Conditions
              </a>
            )}
          </FooterTrademark>
        </FooterTrademarkComponent>
      </FooterDesktopContainer>
    </>
  );
};

const FooterMobile = ({
  store,
  sColor,
  noDescription,
  router,
  callNumber,
  fullYear,
  authAndLogoutModal,
  renderLoginLogoutMobile,
  showVisitorLogin,
  renderSocialMediaProfileDetails,
  storePolicies,
  showSubscriberInput,
  isPaymentNotVisible,
}) => {
  const dsLabel = getFooterDSLabel(store?.theme?.components);
  const { isStoreLogoInFooter, isStoreNameInFooter } = useStoreLogoOrNameVisibility();
  const storeLogo = getActualOrDsStoreLogo(store);
  const { storeAddress, storePhone } = getDummyOrActualContactDetails(store, true);

  return (
    <>
      {authAndLogoutModal()}
      <FooterMobileContainer>
        {!isPaymentNotVisible && !store?.services?.delivery_time_approx && (
          <Section display="flex" style={{ display: 'none' }}>
            <FooterLabel
              style={{
                flex: 0.9,
              }}
            >
              {getCustomCtaText('pay_after_cta')}
            </FooterLabel>
            <PaymentRow>
              <PaymentOption>
                <Image
                  src="/assets/images/ic_paytm@2x.png"
                  alt="Paytm"
                  height={31}
                  width={63}
                  layout="fixed"
                />
              </PaymentOption>
              <PaymentOption>
                <Image
                  src="/assets/images/ic_gpay@2x.png"
                  alt="GPay"
                  height={27}
                  width={57}
                  layout="fixed"
                />
              </PaymentOption>
              <PaymentOption>
                <Image
                  src="/assets/images/ic_phonep@2x.png"
                  alt="Phonpe"
                  height={25}
                  width={25}
                  layout="fixed"
                />
              </PaymentOption>
              <PaymentOption>
                <PaymentCash>Or Cash</PaymentCash>
              </PaymentOption>
            </PaymentRow>
          </Section>
        )}
        <FooterMobileMain>
          {showSubscriberInput ? (
            <Section>
              <SubscriberInput />
            </Section>
          ) : null}
          <StoreDescription>
            <StoreNameImage>
              {storeLogo && isStoreLogoInFooter && (
                <StoreImage>
                  <img loading="lazy" alt="Store Logo" src={storeLogo} />
                </StoreImage>
              )}
              {store?.store_info?.name && isStoreNameInFooter && (
                <StoreName>{store?.store_info?.name}</StoreName>
              )}
            </StoreNameImage>
            <Section>
              {showVisitorLogin && renderLoginLogoutMobile()}
              {storePhone && (
                <>
                  <FooterLabel>Contact us</FooterLabel>
                  <FooterButton
                    color={sColor || store?.store_info?.theme?.colors?.primary_color}
                  >
                    <a href={`tel:${storePhone}`} onClick={callNumber}>
                      <span className="footer-button-phone-icon">
                        <PhoneIcon
                          size={11}
                          color={
                            sColor || store?.store_info?.theme?.colors?.primary_color
                          }
                        />
                      </span>
                      <PhoneNumber
                        color={sColor || store?.store_info?.theme?.colors?.primary_color}
                      >
                        {storePhone}
                      </PhoneNumber>
                    </a>
                  </FooterButton>
                </>
              )}
            </Section>
            {!noDescription && store?.store_info?.description && (
              <StoreAboutUs>
                <FooterLabel>About Us</FooterLabel>
                {store?.store_info?.description &&
                store?.store_info?.description.length > 160 ? (
                  <FooterPara textSelection={true}>
                    {store?.store_info?.description.substr(0, 155)}...
                  </FooterPara>
                ) : (
                  <FooterPara textSelection={true} needLineClip={true}>
                    {store?.store_info?.description}
                  </FooterPara>
                )}
                {store?.store_info?.description.length > 160 && (
                  <ViewMoreText
                    onClick={() => {
                      router.push(getRoute(PATH.ABOUT_US, store?.store_info?.domain));
                    }}
                  >
                    View More
                  </ViewMoreText>
                )}
              </StoreAboutUs>
            )}
            <Section>
              {store?.social_media_profile_details &&
                renderSocialMediaProfileDetails(true)}
            </Section>
          </StoreDescription>
          {!!storeAddress && (
            <>
              <StoreDetails>
                <Section>
                  <FooterLabel>Visit Us</FooterLabel>
                  <FooterPara>{storeAddress}</FooterPara>
                </Section>
              </StoreDetails>
              <FooterMap storeData={store} />
            </>
          )}
        </FooterMobileMain>
        <FooterStorePolicies storePolicies={storePolicies} path={PATH.STORE_POLICIES} />
        <FooterTrademarkComponent>
          <FooterTrademark>
            Copyright {fullYear}
            {dsLabel ? <>. {dsLabel}</> : ''}
          </FooterTrademark>
          <FooterTrademark>
            <a href={CANCELATION_AND_REFUND_POLICY_URL} target="_blank" rel="noreferrer">
              Refunds &amp; Cancellation
            </a>
            <Circle />
            {store?.services?.delivery_time_approx ? (
              <a href={DS_TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
                Terms &amp; Conditions
              </a>
            ) : (
              <a href={DS_TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
                Terms &amp; Conditions
              </a>
            )}
          </FooterTrademark>
        </FooterTrademarkComponent>
      </FooterMobileContainer>
    </>
  );
};

export default PremiumFooter;

export function PhoneIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 21.111}
      height={props.size || 21.111}
      viewBox="0 0 21.111 21.111"
      {...props}
    >
      <path
        d="M19.417 13.857a11.986 11.986 0 01-3.764-.6 1.721 1.721 0 00-1.673.353l-2.374 1.79a13.118 13.118 0 01-5.9-5.9l1.741-2.307a1.706 1.706 0 00.42-1.729 12 12 0 01-.6-3.77A1.7 1.7 0 005.571 0H1.694A1.7 1.7 0 000 1.694a19.439 19.439 0 0019.417 19.417 1.7 1.7 0 001.694-1.694v-3.866a1.7 1.7 0 00-1.694-1.694z"
        fill={props.color}
      />
    </svg>
  );
}

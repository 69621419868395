import { removeDsAdsRouteLink } from '@/utils/removeDsAdsRouteLink';
import React from 'react';

function HorizontalReasonModal(props) {
  return (
    <div className="flex justify-between flew-row">
      <div className="flex flex-column justify-center items-center left-section ml20px">
        <div className="flex left-section-text-body f12px fw6">
          You are seeing this Ad <br></br>
          because owner of this <br></br>
          website using the free<br></br>
          version
        </div>
      </div>
      <div className="flex flex-column justify-center items-start pt20px pl20px pr20px right-section">
        <div className="flex f12px fw6">
          If you own this website<br></br>
          then you can remove this
        </div>
        <div
          className="flex pa12px right-removead-section mt14px"
          onClick={removeDsAdsRouteLink}
        >
          <div className="flex fw7">Remove this Ad</div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalReasonModal;
